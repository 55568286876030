import { useAppContext } from "@/contexts/appContext";
import { Drawer } from "vaul";
import AppLogo2 from "./AppLogo/AppLogo2";
import { Button } from "./ui/button";
import { X } from "lucide-react";
import { useCallback, useMemo } from "react";
import { supabaseService } from "@/services/supabaseService";
import { toast } from "sonner";
import { Link, useNavigate } from "react-router-dom";

export default function Sidebar() {
  const { sidebarOpen, setSidebarOpen } = useAppContext();
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    const supabase = supabaseService.getClient();
    const { error } = await supabase.auth.signOut();

    if (error) {
      const message = error.message || "Error";
      toast.error(message);
    } else {
      navigate(".", { replace: true });
      setSidebarOpen(false);
      toast.success("Successfully logged out");
    }
  }, [navigate, setSidebarOpen]);

  const handleNavItemClick = useCallback(() => {
    setSidebarOpen(false);
  }, [setSidebarOpen]);

  const navItems = useMemo(() => {
    const items = [
      { label: "Scan Your Strip", link: "/tutorial" },
      { label: "Previous Test Results", link: "/results" },
    ];

    return items;
  }, []);

  return (
    <Drawer.Root
      direction="right"
      open={sidebarOpen}
      onOpenChange={setSidebarOpen}
    >
      <Drawer.Portal>
        <Drawer.Overlay className="z-40 fixed inset-0 bg-black/40" />
        <Drawer.Content className="z-40 bg-white flex flex-col rounded-t-[10px] h-full w-full max-w-[400px] mt-24 fixed bottom-0 right-0">
          <Drawer.Title className="hidden">Ribbon</Drawer.Title>
          <div className="p-6 bg-white flex-1 h-full flex flex-col">
            <div className="flex items-center justify-between mb-10">
              <AppLogo2 height={20} width={80} />
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setSidebarOpen(false)}
              >
                <X />
              </Button>
            </div>
            <div className="flex flex-col" onClick={handleNavItemClick}>
              {navItems.map((item, index) => {
                return (
                  <Button
                    asChild
                    variant="link"
                    key={index}
                    className="flex justify-start px-0 py-8 border-b border-gray-300 rounded-none text-black hover:no-underline hover:text-primary text-xl"
                  >
                    <Link to={item.link}>{item.label}</Link>
                  </Button>
                );
              })}
            </div>
            <div className="mt-auto !text-lg">
              <Button variant="ghost" onClick={handleLogout}>
                Logout
              </Button>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
