import { SupabaseClient, createClient } from "@supabase/supabase-js";

class SupabaseService {
  supabaseClient: SupabaseClient;

  constructor() {
    const apiKey = process.env.REACT_APP_SUPABASE_KEY;
    const appUrl = process.env.REACT_APP_SUPABASE_PROJECT_URL;
    if (!apiKey) throw Error("Supabase API key is not defined");
    if (!appUrl) throw Error("Supabase app URL is not defined");
    this.supabaseClient = createClient(appUrl, apiKey);
  }

  public getClient() {
    return this.supabaseClient;
  }

  public async getSession() {
    const { data, error } = await this.supabaseClient.auth.getSession();
    if (error) throw error;
    return data;
  }
}

export const supabaseService = new SupabaseService();
