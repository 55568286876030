import { ReactNode } from "react";
import { AppHeaderComponent } from "./AppHeader";
import { Toaster } from "./ui/sonner";
import Sidebar from "./Sidebar";

interface IProps {
  children?: ReactNode;
}

export default function AppLayout({ children }: IProps) {
  return (
    <div className="min-h-full flex flex-col">
      <div id="app-header">
        <AppHeaderComponent />
      </div>
      <div id="app-main" className="grow flex flex-col">
        {children}
      </div>
      <Sidebar />
      <Toaster theme="light" richColors position="top-right" />
    </div>
  );
}
