import { useCallback, useEffect, useState } from "react";

export enum TimerStatus {
  Running = 1,
  Paused = 2,
  Stopped = 3,
  Expired = 4,
}

interface IProps {
  time?: number;
}

const TIME_STEP = 1000;

export default function useTimer({ time: initialTime = 0 }: IProps) {
  const [state, setState] = useState({
    time: initialTime,
    status: TimerStatus.Stopped,
  });

  const start = useCallback(() => {
    setState((state) => ({ ...state, status: TimerStatus.Running }));
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (state.status === TimerStatus.Running) {
      intervalId = setInterval(() => {
        setState((state) => {
          let nextTime = state.time - TIME_STEP;
          if (nextTime < 0) {
            return {
              ...state,
              status: TimerStatus.Expired,
              time: 0,
            };
          } else {
            return {
              ...state,
              time: nextTime,
            };
          }
        });
      }, TIME_STEP);
    } else if (intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [state.status]);

  return {
    start,
    time: state.time,
    status: state.status,
  };
}
