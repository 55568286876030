export enum ScanMediaType {
  Image = "image",
  Video = "video",
}

export interface IScanStepMedia {
  type: ScanMediaType;
  src: any;
}

interface ScanMediaFunctionProps {
  stipPictureFile?: File;
}

type ScanMediaFunction = ({
  stipPictureFile,
}: ScanMediaFunctionProps) => IScanStepMedia;
export interface IScanStep {
  id: string;
  title: string;
  description?: string;
  media?: IScanStepMedia | ScanMediaFunction;
  btnLabel?: string;
  startTimer?: boolean;
  takePicture?: boolean;
  cameraStep?: boolean;
  metadata?: any;
}
