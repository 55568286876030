import { Button } from "@/components/ui/button";
import {
  ABNORMAL_DETAILS,
  DETAIL,
  INITIAL_DOCTOR_DATA,
  INITIAL_USER_DATA,
  PREFIX,
  stateList,
  SUFFIX,
} from "@/utils/variables";
import { Check, X } from "lucide-react";
import { useMemo, useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";

interface IProps {
  data: {
    predictions: {
      [key: string]: number;
    };
    reagent_colors: {
      [key: string]: number[];
    };
  };
}

export default function ScanResult({ data }: IProps) {
  const [isDoctorView, setIsDoctorView] = useState(false);

  const { state, doctorViewData, userViewData } = useMemo(() => {
    const state: any = {
      kidney: data.predictions.pro > 0 || data.predictions.blo > 0 ? 0 : 1,
      liver: data.predictions.bil > 0 || data.predictions.uro > 1 ? 0 : 1,
      diabetes: data.predictions.glu > 0 || data.predictions.ket > 0 ? 0 : 1,
      infection: data.predictions.leu > 0 || data.predictions.nit > 0 ? 0 : 1,
      hydration: 1,
      ph: 1,
    };

    let normalResultsCount = 0;
    Object.keys(data.predictions).forEach((key) => {
      if (key !== "uro" && key !== "ph" && key !== "sg") {
        if (data.predictions[key] === 0) normalResultsCount++;
      } else if (key === "uro") {
        if (data.predictions.uro === 0 || data.predictions.uro === 1)
          normalResultsCount++;
      } else {
        normalResultsCount++;
      }
    });

    const hasAbnormalResults =
      normalResultsCount < Object.keys(data.predictions).length;

    const newUserViewData: any = { ...INITIAL_USER_DATA };
    const newDoctorViewData: any = { ...INITIAL_DOCTOR_DATA };
    DETAIL.forEach((item) => {
      newDoctorViewData[item].value =
        PREFIX[item][data.predictions[item]] +
        " " +
        SUFFIX[item][data.predictions[item]];
      newUserViewData[item].color = data.reagent_colors[item];
      newDoctorViewData[item].color = data.reagent_colors[item];
      newDoctorViewData[item].prediction = data.predictions[item];

      if (item !== "uro" && item !== "ph" && item !== "sg") {
        if (data.predictions[item] > 0) newUserViewData[item].value = 0;
        else newUserViewData[item].value = 1;
      } else if (item === "uro") {
        if (data.predictions[item] > 1) newUserViewData[item].value = 0;
        else newUserViewData[item].value = 1;
      } else {
        newUserViewData[item].value = 1;
      }
    });

    return {
      state,
      hasAbnormalResults,
      doctorViewData: newDoctorViewData,
      userViewData: newUserViewData,
    };
  }, [data]);

  return (
    <>
      <div className="p-6 pb-0 grow flex flex-col">
        <div className="flex border rounded-button mb-4">
          <Button
            variant="ghost"
            className="grow data-[active=true]:bg-[rgba(0,33,63,0.6)] data-[active=true]:text-white"
            data-active={!isDoctorView}
            onClick={() => setIsDoctorView(false)}
          >
            Your View
          </Button>
          <Button
            variant="ghost"
            className="grow data-[active=true]:bg-[rgba(0,33,63,0.6)] data-[active=true]:text-white"
            data-active={isDoctorView}
            onClick={() => setIsDoctorView(true)}
          >
            Doctor’s View
          </Button>
        </div>
        {isDoctorView ? (
          <>
            <div>
              <Accordion type="multiple" className="w-full">
                {DETAIL.map((item, index) => {
                  return (
                    <AccordionItem
                      value={`item-${index}`}
                      className="border-b border-gray-300"
                      key={index}
                    >
                      <AccordionTrigger className="text-left !no-underline">
                        <div className="flex gap-3">
                          <div
                            className="w-8 h-8 rounded shrink-0"
                            style={{
                              backgroundColor: `rgb(${doctorViewData[item].color})`,
                            }}
                          />
                          <div>
                            <div className="mb-2 font-medium">
                              {doctorViewData[item].title}
                            </div>
                            <div
                              className={`flex items-center ${
                                userViewData[item].value
                                  ? "text-[#1B995A]"
                                  : "text-[#FF0808]"
                              }`}
                            >
                              {doctorViewData[item].value}
                            </div>
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="text-base">
                        <p className="mt-3">{ABNORMAL_DETAILS[item]}</p>
                      </AccordionContent>
                    </AccordionItem>
                  );
                })}
              </Accordion>
              {/* {DETAIL.map((item, index) => {
                return (
                  <div className="flex gap-4 pt-3" key={index}>
                    <div
                      className="w-8 h-8 rounded shrink-0"
                      style={{
                        backgroundColor: `rgb(${doctorViewData[item].color})`,
                      }}
                    />
                    <div className="grow border-b border-gray-300 pb-3">
                      <Collapsible>
                        <CollapsibleTrigger className="w-full text-start">
                          <div className="flex justify-between mb-2 items-center">
                            <span className="font-medium">
                              {doctorViewData[item].title}
                            </span>
                            <ChevronDown />
                          </div>
                          <div
                            className={`flex items-center ${
                              userViewData[item].value
                                ? "text-[#1B995A]"
                                : "text-[#FF0808]"
                            }`}
                          >
                            {doctorViewData[item].value}
                          </div>
                        </CollapsibleTrigger>
                        <CollapsibleContent>
                          <p className="mt-3">{ABNORMAL_DETAILS[item]}</p>
                        </CollapsibleContent>
                      </Collapsible>
                    </div>
                  </div>
                );
              })} */}
            </div>
          </>
        ) : (
          <>
            {stateList.map((item: any, index) => {
              const isNormal = !!state[item.key];
              const iconSize = 20;

              return (
                <div
                  key={index}
                  className="flex w-full items-center justify-between p-4 border-b border-gray-300"
                >
                  <div className="text-xl">{item.title}</div>
                  <div
                    className={`rounded-full p-1 text-white ${
                      isNormal ? "bg-ribbon-green" : "bg-ribbon-red"
                    } `}
                  >
                    {isNormal ? (
                      <Check size={iconSize} />
                    ) : (
                      <X size={iconSize} />
                    )}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
}
