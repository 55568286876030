import { Player } from "@lottiefiles/react-lottie-player";
import lottieTwo from "@/assets/lotties/2lottie.json";
import ButtonsContainer from "@/components/ButtonsContainer";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { TriangleAlert } from "lucide-react";

export default function YourUrine() {
  return (
    <>
      <Player src={lottieTwo} loop autoplay className="max-w-96" />
      <div className="my-4">
        <h2 className="mb-4 text-2.5xl font-medium">
          The multi-analyte urine test
        </h2>
        <p className="mb-4">
          Your urine holds vital information about your health. The Ribbon
          multi-analyte urine test provides you with valuable insights between
          doctor visits.
        </p>
        <Alert variant="warning">
          <TriangleAlert className="h-5 w-5" />
          <AlertDescription>
            Keep the test strip packaging closed when not in use. If the test
            strip packaging is left open the tests will be damaged.
          </AlertDescription>
        </Alert>
      </div>
      <ButtonsContainer>
        <Button asChild variant="outline" className="grow">
          <Link to="/tutorial/our-goal">Next: Our Goal</Link>
        </Button>
      </ButtonsContainer>
    </>
  );
}
