import { useSpring, animated, useChain, useSpringRef } from "@react-spring/web";
import useMeasure from "react-use-measure";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import { SplashColors } from "@/types/common";
import { ReactNode, useState } from "react";
import { getSplashColors } from "@/lib/utils";
import { Link } from "react-router-dom";
import AppLogo2 from "../AppLogo/AppLogo2";
import { useAppContext } from "@/contexts/appContext";
import { Button } from "../ui/button";
import { Menu } from "lucide-react";

interface IProps {
  colors?: SplashColors;
  children?: ReactNode;
}

const LOGO_WIDTH = 80;
const LOGO_HEIGHT = 17;

export default function Splash({
  colors = getSplashColors(),
  children,
}: IProps) {
  const windowDimensions = useWindowDimensions();
  const [splashRef, { height: splashHeight }] = useMeasure();
  const [logoContainerRef, logoContainerBounds] = useMeasure();
  const [splashTopRef, splashTopDimensions] = useMeasure();
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const isAppJustLoaded = true;
  const { isNavigationInteracted, isLoggedIn, setSidebarOpen } =
    useAppContext();

  const splashAnimationRef = useSpringRef();
  const logoFadeAnimationRef = useSpringRef();
  const logoAnimationRef = useSpringRef();
  const contentAnimationRef = useSpringRef();

  const splashAnimationConfig = isNavigationInteracted
    ? {
        tension: 210,
      }
    : {};

  const getAnimationFromTo = (animation: { from: any; to: any }) => {
    const from = isAppJustLoaded ? animation.from : animation.to;
    const to = animation.to;
    return { from, to };
  };

  const splashAnimation = {
    from: {
      height: windowDimensions.height,
    },
    to: {
      height: splashHeight,
    },
  };

  const logoFadeAnimation = {
    from: { opacity: 0 },
    to: { opacity: 1 },
  };

  const contentFadeAnimation = {
    from: { opacity: 0 },
    to: { opacity: 1 },
  };

  const logoAnimation = {
    from: {
      scale: 2,
      x: windowDimensions.width / 2 - logoContainerBounds.left - LOGO_WIDTH / 2,
      y:
        windowDimensions.height / 2 - logoContainerBounds.top - LOGO_HEIGHT / 2,
    },
    to: {
      scale: 1,
      x: 0,
      y: 0,
    },
  };

  const [splashSpring] = useSpring(
    () => ({
      ...getAnimationFromTo(splashAnimation),
      ref: splashAnimationRef,
      config: {
        clamp: true,
        ...splashAnimationConfig,
      },
      onRest: () => {
        setAnimationPlayed(true);
      },
    }),
    [splashAnimation, splashAnimationConfig]
  );

  const [logoFadeSpring] = useSpring(
    () => ({
      ...getAnimationFromTo(logoFadeAnimation),
      ref: logoFadeAnimationRef,
      config: {
        friction: 30,
      },
    }),
    [logoFadeAnimation]
  );

  const [logoSpring] = useSpring(
    () => ({
      ...getAnimationFromTo(logoAnimation),
      ref: logoAnimationRef,
    }),
    [logoAnimation]
  );

  const [contentFadeSpring] = useSpring(
    () => ({
      ...getAnimationFromTo(contentFadeAnimation),
      ref: contentAnimationRef,
      config: {
        friction: 50,
      },
    }),
    [contentFadeAnimation]
  );

  const [menuButtonSpring] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      delay: 2000,
    }),
    []
  );

  useChain(
    [
      logoFadeAnimationRef,
      logoAnimationRef,
      splashAnimationRef,
      contentAnimationRef,
    ],
    [0, 2, animationPlayed ? 0 : 2, 2.6]
  );

  return (
    <animated.div
      className="transition-colors duration-300"
      style={{ ...splashSpring, backgroundColor: colors.background }}
    >
      <div className="splash" ref={splashRef}>
        <div style={{ height: splashTopDimensions.height }}>
          <div
            ref={splashTopRef}
            className="splash-top flex items-center justify-between py-6 px-6 fixed top-0 z-20 w-full"
            style={{ backgroundColor: colors.background }}
          >
            <div
              className="logo-container"
              ref={logoContainerRef}
              style={{ height: LOGO_HEIGHT }}
            >
              <animated.div
                style={{
                  ...logoSpring,
                  ...logoFadeSpring,
                  position: animationPlayed ? "relative" : "fixed",
                }}
              >
                <Link to="/">
                  <AppLogo2
                    width={LOGO_WIDTH}
                    height={LOGO_HEIGHT}
                    color={colors.logo}
                  />
                </Link>
              </animated.div>
            </div>
            {isLoggedIn && (
              <animated.div style={menuButtonSpring}>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setSidebarOpen(true)}
                  className="text-ribbon-blue hover:text-ribbon-blue"
                >
                  <Menu />
                </Button>
              </animated.div>
            )}
          </div>
        </div>
        {children && (
          <animated.div style={contentFadeSpring}>
            <div className="splash-content px-6 pb-6">{children}</div>
          </animated.div>
        )}
      </div>
    </animated.div>
  );
}
