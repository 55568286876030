import { SplashColors } from "@/types/common";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { COLORS } from "@/utils/colors";
import { assignInWith, isUndefined } from "lodash";

const defaultSplashColors: SplashColors = {
  background: COLORS["light-blue"],
  logo: COLORS.blue,
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getSplashColors({
  background,
  logo,
}: {
  background?: string;
  logo?: string;
} = defaultSplashColors) {
  const colors = assignInWith(
    defaultSplashColors,
    { background, logo },
    (objectVal, sourceVal) => {
      return isUndefined(sourceVal) ? objectVal : sourceVal;
    }
  );

  return colors;
}
