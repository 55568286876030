import { useAppContext } from "@/contexts/appContext";
import { SplashColors } from "@/types/common";
import { memo, ReactNode, useEffect } from "react";
import Splash from "./Splash/Splash";
import { COLORS } from "@/utils/colors";
import { isEqual } from "lodash";

interface IHeaderProps {
  splashColors?: SplashColors;
  renderContent?: () => ReactNode;
}

function HeaderUtilComponent({
  splashColors = { background: COLORS["light-blue"], logo: COLORS.blue },
  renderContent = () => null,
}: IHeaderProps) {
  const {
    setSplashColors,
    setHeaderContent,
    splashColors: currentSplashColors,
  } = useAppContext();

  useEffect(() => {
    setHeaderContent(renderContent);
  }, [renderContent, setHeaderContent]);

  useEffect(() => {
    if (!isEqual(splashColors, currentSplashColors)) {
      setSplashColors(splashColors);
    }
  }, [currentSplashColors, setSplashColors, splashColors]);

  return null;
}

export function AppHeaderComponent() {
  const { splashColors, headerContent } = useAppContext();

  return <Splash colors={splashColors}>{headerContent}</Splash>;
}

const Header = memo(HeaderUtilComponent);

export default Header;
