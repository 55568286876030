import { Player } from "@lottiefiles/react-lottie-player";
import lottieFour from "@/assets/lotties/4lottie.json";
import ButtonsContainer from "@/components/ButtonsContainer";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

export default function OurGoal() {
  return (
    <>
      <Player src={lottieFour} loop autoplay className="max-w-96" />
      <div className="my-4">
        <h2 className="mb-4 text-2.5xl font-medium">Detect early</h2>
        <p className="mb-4">
          Ribbon provides a clue that something may be wrong, so medical
          professionals can look further into the issue.
        </p>
        <p className="text-sm text-ribbon-charcoal-700">
          *Ribbon still recommends visiting your doctor for annual checkups.
          This product is not intended for use in diagnosing diseases or other
          conditions; determining or monitoring the state of health; or curing,
          mitigating, treating or preventing and diseases.
        </p>
      </div>
      <ButtonsContainer>
        <Button asChild variant="outline" className="grow">
          <Link to="/scan">Begin Test</Link>
        </Button>
      </ButtonsContainer>
    </>
  );
}
