import Header from "@/components/AppHeader";
import ScanResult from "@/components/ScanResult";
import { useAppContext } from "@/contexts/appContext";
import { supabaseService } from "@/services/supabaseService";
import { COLORS } from "@/utils/colors";
import { QUERY_KEY } from "@/utils/variables";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { checkHasAbnormalResults } from "@/utils/scanUtils";
import ButtonsContainer from "@/components/ButtonsContainer";
import { Button } from "@/components/ui/button";
import Spinner from "@/components/Spinner";

export default function SingleResultPage() {
  const { id } = useParams();
  const { session } = useAppContext();
  const supabase = supabaseService.getClient();

  const resultQuery = useQuery({
    queryKey: [QUERY_KEY.scanResults, id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("UserScan")
        .select("*, Scan (*, Result (*))")
        .eq("user_uid", session.user.id)
        .eq("id", id)
        .single();

      if (error) {
        throw error;
      } else {
        return data;
      }
    },
  });

  const data = useMemo(() => {
    if (resultQuery.isSuccess && resultQuery.data?.Scan?.Result) {
      const resultData = resultQuery.data.Scan.Result;

      if (resultData[0]) {
        const color_data = resultData[0].color_data;
        const { card_colors, predictions, reagent_colors } = color_data;

        return { card_colors, predictions, reagent_colors };
      }
    } else {
      return null;
    }
  }, [resultQuery]);

  const { hasAbnormalResults } = useMemo(() => {
    let hasAbnormalResults = undefined;
    if (data) {
      hasAbnormalResults = checkHasAbnormalResults(data.predictions);
    }
    return {
      hasAbnormalResults,
    };
  }, [data]);

  const headerProps = useMemo(() => {
    if (hasAbnormalResults === undefined) {
      return {
        content: resultQuery.isLoading ? (
          <div>
            <Spinner />
          </div>
        ) : undefined,
      };
    }

    if (hasAbnormalResults) {
      return {
        splashColors: {
          background: COLORS.rose["100"],
          logo: COLORS.rose["500"],
        },
        content: (
          <div className="text-ribbon-rose-800">
            <h2 className="text-2xl font-medium mb-2">Attention!</h2>
            <p className="text-3xl font-light">Some Results Are Abnormal</p>
          </div>
        ),
      };
    } else {
      return {
        splashColors: {
          background: COLORS.lime["100"],
          logo: COLORS.lime["500"],
        },
        content: (
          <div className="text-ribbon-lime-800">
            <h2 className="text-2xl font-medium mb-2">Good News!</h2>
            <p className="text-3xl font-light">Your Results Are Normal</p>
          </div>
        ),
      };
    }
  }, [hasAbnormalResults, resultQuery.isLoading]);

  const renderHeaderContent = useCallback(() => {
    return headerProps.content;
  }, [headerProps.content]);

  return (
    <>
      <Header
        renderContent={renderHeaderContent}
        splashColors={headerProps.splashColors}
      />
      <div className="max-w-screen-sm w-full mx-auto">
        {data && <ScanResult data={data} />}
        <ButtonsContainer>
          <Button asChild variant="secondary" className="px-8">
            <Link to="/results">Back</Link>
          </Button>
        </ButtonsContainer>
      </div>
    </>
  );
}
