import { COLORS } from "@/utils/colors";
import { SplashColors } from "@/types/common";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLoaderData, useLocation } from "react-router-dom";
import posthog from "posthog-js";
import { supabaseService } from "@/services/supabaseService";

interface IAppContext {
  splashColors: SplashColors;
  setSplashColors: (colors: SplashColors) => void;
  headerContent: ReactNode;
  setHeaderContent: (content: () => ReactNode) => void;
  isNavigationInteracted: boolean;
  isLoggedIn: boolean;
  session: any;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  reloadSession: () => Promise<void>;
}

const defaultValue: IAppContext = {
  splashColors: {
    background: COLORS["light-blue"],
    logo: COLORS.blue,
  },
  setSplashColors: () => {},
  headerContent: null,
  setHeaderContent: () => {},
  isNavigationInteracted: false,
  isLoggedIn: false,
  session: undefined,
  sidebarOpen: false,
  setSidebarOpen: () => {},
  reloadSession: async () => {},
};

const AppContext = createContext(defaultValue);

export function AppContextProvider({ children }: { children?: ReactNode }) {
  const sessionLoaderData: any = useLoaderData();
  const [session, setSession] = useState(sessionLoaderData);
  const [splashColors, setSplashColors] = useState(defaultValue.splashColors);
  const [headerContent, setHeaderContent] = useState<any>(
    defaultValue.headerContent
  );
  const [initialLocationPathname] = useState(window.location.pathname);
  const [isNavigationInteracted, setIsNavigationInteracted] = useState(
    defaultValue.isNavigationInteracted
  );
  const [sidebarOpen, setSidebarOpen] = useState(defaultValue.sidebarOpen);
  const location = useLocation();
  const isLoggedIn = !!session?.user;

  const reloadSession = useCallback(async () => {
    const {
      data: { session },
    } = await supabaseService.getClient().auth.getSession();

    setSession(session);
  }, []);

  useEffect(() => {
    setSession(sessionLoaderData);
  }, [sessionLoaderData]);

  useEffect(() => {
    if (location.pathname !== initialLocationPathname) {
      setIsNavigationInteracted(true);
    }
  }, [initialLocationPathname, location.pathname]);

  useEffect(() => {
    const env = process.env.REACT_APP_ENV ?? "development";

    if (["prod", "stg"].includes(env)) {
      posthog.capture("$pageview");
    }
  }, [location]);

  return (
    <AppContext.Provider
      value={{
        splashColors,
        setSplashColors,
        headerContent,
        setHeaderContent,
        isNavigationInteracted,
        isLoggedIn,
        session,
        sidebarOpen,
        setSidebarOpen,
        reloadSession,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
