export const COLORS = {
  "light-blue": "#D5FEFF",
  teal: {
    "300": "#D6F4F5",
    "500": "#218285",
    "700": "#0A585B",
  },
  charcoal: {
    700: "#39536A",
  },
  "slate-gray": "#114948",
  slate: {
    300: "#C2D5D6",
  },
  purple: "#A36CCD",
  red: "#FD4848",
  green: "#5FB900",
  rose: {
    100: "#FFE2E2",
    500: "#EE4E82",
    800: "#BC0003",
  },
  lime: {
    100: "#DCFFB7",
    500: "#4C7566",
    800: "#006811",
  },
  blue: "#0052FF",
};
