import { IScanStep, ScanMediaType } from "@/types/Scan";
import PhotoOne from "../assets/img/photo_one_urine_16x9.jpg";
import PhotoThree from "../assets/img/photo_three_black_area.jpg";
import PhotoFour from "../assets/img/pre-scan-image.jpg";
import ErrorImage from "../assets/img/error-image.png";
import ribbonLoop from "../assets/mp4/Ribbon_Dip_Loop_Compressed.mp4";

export const CONFIRM_PHOTO_STEP_ID = "confirm-photo";
export const ANALYZING_STRIP_STEP_ID = "analyzing-strip";
export const RESULT_STEP_ID = "result";
export const ERROR_STEP_ID = "error";
export const DIP_STEP_ID = "dip";

export const STEPS: IScanStep[] = [
  {
    id: "urinate",
    title: "Urinate in the provided collection cup.",
    description:
      "Try to fill the cup at least 3/4 of the way to ensure a good result.",
    btnLabel: "Next : Dip The Test-Strip...",
    media: {
      type: ScanMediaType.Image,
      src: PhotoOne,
    },
  },
  {
    id: DIP_STEP_ID,
    title: `Dip the test strip for 1-2 seconds, dry the edge, and place on color card.`,
    description:
      "Confirm all pads have been wetted by clicking the button below.",
    btnLabel: "All Pads Were Wetted",
    media: {
      type: ScanMediaType.Video,
      src: ribbonLoop,
    },
  },
  {
    id: "check",
    title: "Be sure the test strip is in the black area.",
    description:
      "Don’t worry, as long as the strip is in the black rectangle, it can be upside down.",
    btnLabel: "Next : Take A Photo",
    startTimer: true,
    media: {
      type: ScanMediaType.Image,
      src: PhotoThree,
    },
  },
  {
    id: "take-photo",
    title:
      "When the timer runs out, take a photo of the entire card and strip.",
    description:
      "Make sure all 4 black corner squares are visible in your photo. Ribbon will then analyze your photo and show you the results.",
    btnLabel: "Take Picture",
    takePicture: true,
    media: {
      type: ScanMediaType.Image,
      src: PhotoFour,
    },
    metadata: {
      showTimer: true,
    },
  },
  {
    id: CONFIRM_PHOTO_STEP_ID,
    title: "If Your Photo In-Focus and In Good Lighting?",
    media: ({ stipPictureFile }) => {
      return {
        type: ScanMediaType.Image,
        src: stipPictureFile ? URL.createObjectURL(stipPictureFile) : "",
      };
    },
    metadata: {
      showTimer: true,
    },
  },
  {
    id: ANALYZING_STRIP_STEP_ID,
    title: "Analyzing Strip...",
    media: ({ stipPictureFile }) => {
      return {
        type: ScanMediaType.Image,
        src: stipPictureFile ? URL.createObjectURL(stipPictureFile) : "",
      };
    },
  },
  {
    id: RESULT_STEP_ID,
    title: "Result",
  },
  {
    id: ERROR_STEP_ID,
    title: "Whoops!",
    media: {
      type: ScanMediaType.Image,
      src: ErrorImage,
    },
    description:
      "Sorry, we weren’t able to analyze your photo. Make sure your photo is focued, well-lit and all four black, corner squares are showing",
  },
];

export function reagentColorToRgbString(rgbArray: number[]) {
  return `rgb(${rgbArray.join(", ")})`;
}

export function checkHasAbnormalResults(predictions: {
  [key: string]: number;
}) {
  let normalResultsCount = 0;
  Object.keys(predictions).forEach((key) => {
    if (key !== "uro" && key !== "ph" && key !== "sg") {
      if (predictions[key] === 0) normalResultsCount++;
    } else if (key === "uro") {
      if (predictions.uro === 0 || predictions.uro === 1) normalResultsCount++;
    } else {
      normalResultsCount++;
    }
  });

  const hasAbnormalResults =
    normalResultsCount < Object.keys(predictions).length;

  return hasAbnormalResults;
}
