import { useAppContext } from "@/contexts/appContext";
import { useSpring, animated } from "@react-spring/web";
import { ReactNode } from "react";
import useMeasure from "react-use-measure";

interface IProps {
  children?: ReactNode;
}

export default function ButtonsContainer({ children }: IProps) {
  const { isNavigationInteracted } = useAppContext();
  const [ref, dimensions] = useMeasure();
  const [fadeAnimation] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: {
        mass: 5,
      },
      delay: isNavigationInteracted ? 0 : 2500,
    }),
    [isNavigationInteracted]
  );

  return (
    <div
      style={{ height: dimensions.height + "px" }}
      className="buttons-container"
    >
      <div ref={ref} className="fixed bottom-0 left-0 w-full">
        <animated.div
          className="mt-auto sticky bottom-0 py-5 flex items-center gap-4 max-w-screen-sm mx-auto px-6"
          style={fadeAnimation}
        >
          {children}
        </animated.div>
      </div>
    </div>
  );
}
