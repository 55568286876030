import Header from "@/components/AppHeader";
import ButtonsContainer from "@/components/ButtonsContainer";
import ScanResult from "@/components/ScanResult";
import { Button } from "@/components/ui/button";
import { COLORS } from "@/utils/colors";
import { checkHasAbnormalResults } from "@/utils/scanUtils";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

interface IProps {
  data: {
    predictions: {
      [key: string]: number;
    };
    reagent_colors: {
      [key: string]: number[];
    };
  };
}

export default function Result({ data }: IProps) {
  const { hasAbnormalResults } = useMemo(() => {
    const hasAbnormalResults = checkHasAbnormalResults(data.predictions);
    return {
      hasAbnormalResults,
    };
  }, [data]);

  const headerProps = useMemo(() => {
    if (hasAbnormalResults) {
      return {
        splashColors: {
          background: COLORS.rose["100"],
          logo: COLORS.rose["500"],
        },
        content: (
          <div className="text-ribbon-rose-800">
            <h2 className="text-2xl font-medium mb-2">Attention!</h2>
            <p className="text-3xl font-light">
              Some Results Are Abnormal Today
            </p>
          </div>
        ),
      };
    } else {
      return {
        splashColors: {
          background: COLORS.lime["100"],
          logo: COLORS.lime["500"],
        },
        content: (
          <div className="text-ribbon-lime-800">
            <h2 className="text-2xl font-medium mb-2">Good News!</h2>
            <p className="text-3xl font-light">Your Results Are Normal</p>
          </div>
        ),
      };
    }
  }, [hasAbnormalResults]);

  const renderHeaderContent = useCallback(() => {
    return headerProps.content;
  }, [headerProps.content]);

  return (
    <>
      <Header
        splashColors={headerProps.splashColors}
        renderContent={renderHeaderContent}
      />
      <div className="max-w-screen-sm mx-auto w-full">
        <ScanResult data={data} />
        <ButtonsContainer>
          <div className="flex flex-col justify-stretch gap-3 w-full">
            <Button asChild variant="outline" className="w-full">
              <Link to="/scan">Test Again</Link>
            </Button>
            <Button asChild variant="outline">
              <a
                href="https://ribboncheckup.com/pages/select-your-kit"
                target="_blank"
                rel="noopener noreferrer"
              >
                Order A New Test
              </a>
            </Button>
          </div>
        </ButtonsContainer>
      </div>
    </>
  );
}
